export const greens = [
  '32B67A',
  // Update to the actual color palette
  '1B5E20',
  '2E7D32',
  '388E3C',
  '43A047',
  '4CAF50',
  '66BB6A',
  '81C784',
  'A5D6A7',
  'C8E6C9'
]
export const blues = [
  '005397',
  // Update to the actual color palette
  "0D47A1",
  "1565C0",
  "1976D2",
  "1E88E5",
  "2196F3",
  "42A5F5",
  "64B5F6",
  "90CAF9",
  "BBDEFB"
]
export const reds = [
  'F46363',
  // Update to the actual color palette
  "B71C1C",
  "C62828",
  "D32F2F",
  "E53935",
  "F44336",
  "EF5350",
  "E57373",
  "EF9A9A",
  "FFCDD2"
]
export const yellows = [
  'F4A32A',
  // Update to the actual color palette
  "F57F17",
  "F9A825",
  "FBC02D",
  "FDD835",
  "FFEB3B",
  "FFEE58",
  "FFF176",
  "FFF59D",
  "FFF9C4"
]
