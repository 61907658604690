/* tslint:disable */
/* eslint-disable */
/**
 * Pyrra
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QueryMatchers,
    QueryMatchersFromJSON,
    QueryMatchersFromJSONTyped,
    QueryMatchersToJSON,
} from './QueryMatchers';

/**
 * 
 * @export
 * @interface Query
 */
export interface Query {
    /**
     * 
     * @type {string}
     * @memberof Query
     */
    metric: string;
    /**
     * 
     * @type {string}
     * @memberof Query
     */
    name?: string;
    /**
     * 
     * @type {Array<QueryMatchers>}
     * @memberof Query
     */
    matchers?: Array<QueryMatchers>;
}

export function QueryFromJSON(json: any): Query {
    return QueryFromJSONTyped(json, false);
}

export function QueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Query {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metric': json['metric'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'matchers': !exists(json, 'matchers') ? undefined : ((json['matchers'] as Array<any>).map(QueryMatchersFromJSON)),
    };
}

export function QueryToJSON(value?: Query | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metric': value.metric,
        'name': value.name,
        'matchers': value.matchers === undefined ? undefined : ((value.matchers as Array<any>).map(QueryMatchersToJSON)),
    };
}

