/* tslint:disable */
/* eslint-disable */
/**
 * Pyrra
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MultiBurnrateAlert,
    MultiBurnrateAlertFromJSON,
    MultiBurnrateAlertToJSON,
    Objective,
    ObjectiveFromJSON,
    ObjectiveToJSON,
    ObjectiveStatus,
    ObjectiveStatusFromJSON,
    ObjectiveStatusToJSON,
    QueryRange,
    QueryRangeFromJSON,
    QueryRangeToJSON,
} from '../models';

export interface GetMultiBurnrateAlertsRequest {
    expr: string;
    grouping?: string;
    inactive?: boolean;
}

export interface GetObjectiveErrorBudgetRequest {
    expr: string;
    grouping?: string;
    start?: number;
    end?: number;
}

export interface GetObjectiveStatusRequest {
    expr: string;
    grouping?: string;
}

export interface GetREDErrorsRequest {
    expr: string;
    grouping?: string;
    start?: number;
    end?: number;
}

export interface GetREDRequestsRequest {
    expr: string;
    grouping?: string;
    start?: number;
    end?: number;
}

export interface ListObjectivesRequest {
    expr?: string;
}

/**
 * 
 */
export class ObjectivesApi extends runtime.BaseAPI {

    /**
     * Get the MultiBurnrateAlerts for the Objective
     */
    async getMultiBurnrateAlertsRaw(requestParameters: GetMultiBurnrateAlertsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<MultiBurnrateAlert>>> {
        if (requestParameters.expr === null || requestParameters.expr === undefined) {
            throw new runtime.RequiredError('expr','Required parameter requestParameters.expr was null or undefined when calling getMultiBurnrateAlerts.');
        }

        const queryParameters: any = {};

        if (requestParameters.expr !== undefined) {
            queryParameters['expr'] = requestParameters.expr;
        }

        if (requestParameters.grouping !== undefined) {
            queryParameters['grouping'] = requestParameters.grouping;
        }

        if (requestParameters.inactive !== undefined) {
            queryParameters['inactive'] = requestParameters.inactive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/objectives/alerts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MultiBurnrateAlertFromJSON));
    }

    /**
     * Get the MultiBurnrateAlerts for the Objective
     */
    async getMultiBurnrateAlerts(requestParameters: GetMultiBurnrateAlertsRequest, initOverrides?: RequestInit): Promise<Array<MultiBurnrateAlert>> {
        const response = await this.getMultiBurnrateAlertsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get ErrorBudget graph sample pairs
     */
    async getObjectiveErrorBudgetRaw(requestParameters: GetObjectiveErrorBudgetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QueryRange>> {
        if (requestParameters.expr === null || requestParameters.expr === undefined) {
            throw new runtime.RequiredError('expr','Required parameter requestParameters.expr was null or undefined when calling getObjectiveErrorBudget.');
        }

        const queryParameters: any = {};

        if (requestParameters.expr !== undefined) {
            queryParameters['expr'] = requestParameters.expr;
        }

        if (requestParameters.grouping !== undefined) {
            queryParameters['grouping'] = requestParameters.grouping;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/objectives/errorbudget`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryRangeFromJSON(jsonValue));
    }

    /**
     * Get ErrorBudget graph sample pairs
     */
    async getObjectiveErrorBudget(requestParameters: GetObjectiveErrorBudgetRequest, initOverrides?: RequestInit): Promise<QueryRange> {
        const response = await this.getObjectiveErrorBudgetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get objective status
     */
    async getObjectiveStatusRaw(requestParameters: GetObjectiveStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ObjectiveStatus>>> {
        if (requestParameters.expr === null || requestParameters.expr === undefined) {
            throw new runtime.RequiredError('expr','Required parameter requestParameters.expr was null or undefined when calling getObjectiveStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.expr !== undefined) {
            queryParameters['expr'] = requestParameters.expr;
        }

        if (requestParameters.grouping !== undefined) {
            queryParameters['grouping'] = requestParameters.grouping;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/objectives/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ObjectiveStatusFromJSON));
    }

    /**
     * Get objective status
     */
    async getObjectiveStatus(requestParameters: GetObjectiveStatusRequest, initOverrides?: RequestInit): Promise<Array<ObjectiveStatus>> {
        const response = await this.getObjectiveStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a matrix of error percentage by label
     */
    async getREDErrorsRaw(requestParameters: GetREDErrorsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QueryRange>> {
        if (requestParameters.expr === null || requestParameters.expr === undefined) {
            throw new runtime.RequiredError('expr','Required parameter requestParameters.expr was null or undefined when calling getREDErrors.');
        }

        const queryParameters: any = {};

        if (requestParameters.expr !== undefined) {
            queryParameters['expr'] = requestParameters.expr;
        }

        if (requestParameters.grouping !== undefined) {
            queryParameters['grouping'] = requestParameters.grouping;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/objectives/red/errors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryRangeFromJSON(jsonValue));
    }

    /**
     * Get a matrix of error percentage by label
     */
    async getREDErrors(requestParameters: GetREDErrorsRequest, initOverrides?: RequestInit): Promise<QueryRange> {
        const response = await this.getREDErrorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a matrix of requests by label
     */
    async getREDRequestsRaw(requestParameters: GetREDRequestsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QueryRange>> {
        if (requestParameters.expr === null || requestParameters.expr === undefined) {
            throw new runtime.RequiredError('expr','Required parameter requestParameters.expr was null or undefined when calling getREDRequests.');
        }

        const queryParameters: any = {};

        if (requestParameters.expr !== undefined) {
            queryParameters['expr'] = requestParameters.expr;
        }

        if (requestParameters.grouping !== undefined) {
            queryParameters['grouping'] = requestParameters.grouping;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/objectives/red/requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryRangeFromJSON(jsonValue));
    }

    /**
     * Get a matrix of requests by label
     */
    async getREDRequests(requestParameters: GetREDRequestsRequest, initOverrides?: RequestInit): Promise<QueryRange> {
        const response = await this.getREDRequestsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Objectives
     */
    async listObjectivesRaw(requestParameters: ListObjectivesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Objective>>> {
        const queryParameters: any = {};

        if (requestParameters.expr !== undefined) {
            queryParameters['expr'] = requestParameters.expr;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/objectives`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ObjectiveFromJSON));
    }

    /**
     * List Objectives
     */
    async listObjectives(requestParameters: ListObjectivesRequest = {}, initOverrides?: RequestInit): Promise<Array<Objective>> {
        const response = await this.listObjectivesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
